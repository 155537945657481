import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { TargetType } from 'api/firmware-api';
import { WhiteListApi } from 'api/white-list-api';
import { Field } from 'components/Field/Field';
import { Loading } from 'components/Loading/Loading';
import { DropDown } from 'components/Select/DropDown/DropDown';
import { UploadWhiteListModal } from 'features/FirmwarePage/WhiteLists/Forms/UploadWhiteListModal/UploadWhiteListModal';
import { useWhiteListCreateMutation } from 'helpers/react-query/devices-white-lists-query-hooks';
import { getDevicesWhiteListsQueryKey } from 'helpers/react-query/query-keys';
import { UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { FirmwareReleaseModel, FirmwareReleases, mapWhiteListsToDropDownOption } from '../../utils';

import releaseStyles from './../FirmwareRelease.module.scss';
import styles from './WhiteListField.module.scss';

export type WhiteListFieldProps = {
  releaseIndex: number;
  release: FirmwareReleaseModel;
  form: UseApplicationFormReturn<FirmwareReleases, any>;
};

export const WhiteListField: React.FC<WhiteListFieldProps> = ({
  form: { control, register, formState },
  release,
  releaseIndex,
}) => {
  const errors = formState.errors.releases?.[releaseIndex];
  const [isWhiteListModalOpen, setWhiteListModalOpen] = useState<boolean>(false);
  const handleWhiteListUploadClose = () => setWhiteListModalOpen(false);
  const {
    data: whiteLists,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: getDevicesWhiteListsQueryKey(),
    queryFn: ({ signal }) => WhiteListApi.getList({ signal }),
    staleTime: 60000, // one minute, avoids excessive refetching
  });

  const createWhiteListMutation = useWhiteListCreateMutation(async () => {
    handleWhiteListUploadClose();
    await refetch();
  });
  const whiteListId = register(`releases.${releaseIndex}.whiteListId`, {
    required: { value: release.type === TargetType.ByWhiteList, message: 'Required' },
  });
  return (
    <Field title={'White list'} fieldClassName={clsx(releaseStyles.complexField, styles.loadingContainer)}>
      <Controller
        control={control}
        name={whiteListId.name}
        render={({ field: { ref, value, ...field } }) => (
          <Loading loading={isLoading || isRefetching}>
            <DropDown
              {...field}
              value={value ?? null}
              placeholder={'Select white list file'}
              options={mapWhiteListsToDropDownOption(whiteLists ?? [])}
              error={!!errors?.whiteListId}
              helperText={errors?.whiteListId?.message}
            />
          </Loading>
        )}
      />
      <a className={styles.link} onClick={() => setWhiteListModalOpen(true)}>
        Upload white list
      </a>
      {isWhiteListModalOpen && (
        <UploadWhiteListModal
          title="Upload white list"
          open={isWhiteListModalOpen}
          onClose={handleWhiteListUploadClose}
          onSubmit={createWhiteListMutation.mutateAsync}
          isBusy={createWhiteListMutation.isPending}
        />
      )}
    </Field>
  );
};
