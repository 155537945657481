import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { WhiteListsQueryParameters, WhiteListApi, WhiteListDto, SaveWhiteListDto } from 'api/white-list-api';
import { useCallback } from 'react';
import { getDevicesWhiteListsPageQueryKey } from './query-keys';
import { PageDto } from 'api/common';
import { getNextPageParam } from './infinite-queries-utils';

export function useInfiniteDevicesWhiteListsPageQuery(queryParams: Omit<WhiteListsQueryParameters, 'pageNumber'>) {
  return useInfiniteQuery({
    queryKey: getDevicesWhiteListsPageQueryKey(queryParams),
    queryFn: async ({ pageParam }) =>
      await WhiteListApi.getPage({
        ...queryParams,
        pageNumber: pageParam,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => getNextPageParam(lastPage, pages, queryParams),
  });
}

export const useInvalidateDevicesWhiteListsPageQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries({ queryKey: getDevicesWhiteListsPageQueryKey() }),
    [queryClient],
  );
};

export const useWhiteListCreateMutation = (onSuccess?: () => void) =>
  useMutation({
    mutationFn: (whiteList: SaveWhiteListDto) => WhiteListApi.create(whiteList),
    onSuccess,
  });

export const useWhiteListUpdateMutation = (onSuccess?: () => void) =>
  useMutation({
    mutationFn: (whiteList: SaveWhiteListDto) => WhiteListApi.update(whiteList),
    onSuccess,
  });

export const useWhiteListDeleteMutation = (onSuccess?: () => void) =>
  useMutation({
    mutationFn: (id: string) => WhiteListApi.delete(id),
    onSuccess,
  });
