import { useInfiniteQuery } from '@tanstack/react-query';

import { FirmwareApi, FirmwareQueryParameters } from 'api/firmware-api';
import { getNextPageParam } from './react-query/infinite-queries-utils';
import { getFirmwarePageQueryKey } from './react-query/query-keys';

export function useInfiniteFirmwareQuery(queryParams: Omit<FirmwareQueryParameters, 'pageNumber'>) {
  const query = useInfiniteQuery({
    queryKey: getFirmwarePageQueryKey(queryParams),
    queryFn: async ({ pageParam }) =>
      await FirmwareApi.get({
        ...queryParams,
        pageNumber: pageParam,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => getNextPageParam(lastPage, pages, queryParams),
  });

  return query;
}
