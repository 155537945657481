import {
  tenantsEqual,
  getTenantsAvailableForSelection,
} from 'features/NotificationsPage/common/TenantModal/useSelectedTenants';
import { useMemo, useEffect } from 'react';
import { useNotificationTargetArgs } from './BaseNotificationForm';

export const useNotificationTarget = (args: useNotificationTargetArgs) => {
  const {
    form: { watch, setValue },
    targets,
  } = args;
  const { stages, countries, tenants } = watch();
  const countriesAvailableForSelection = useMemo(
    () => [
      ...new Set(
        targets?.tenants
          .filter((tenant) => stages.length === 0 || stages.includes(tenant.stage))
          .map((tenant) => tenant.countryCode) ?? [],
      ),
    ],
    [targets?.tenants, stages],
  );

  useEffect(() => {
    const filteredCountries = countries.filter((x) => countriesAvailableForSelection.includes(x));
    setValue('countries', filteredCountries);
  }, [countriesAvailableForSelection]);

  const onStagesChange = (stages: string[]) =>
    setValue('tenants', tenants?.filter((x) => stages.length === 0 || stages.includes(x.stage)) ?? []);

  const onCountriesChange = (countries: string[]) =>
    setValue('tenants', tenants?.filter((x) => countries.length === 0 || countries.includes(x.countryCode)) ?? []);

  const existingTenants = useMemo(
    () =>
      targets?.tenants.map((x) => {
        return { ...x, isSelected: tenants?.length === 0 || !!tenants?.some((y) => tenantsEqual(x, y)) };
      }) ?? [],
    [targets?.tenants, tenants],
  );
  const tenantsAvailableForSelection = getTenantsAvailableForSelection(existingTenants, stages, countries);

  return {
    tenantsAvailableForSelection,
    countriesAvailableForSelection,
    existingTenants,
    onCountriesChange,
    onStagesChange,
  };
};
