import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export type AvailableNotificationTargets = {
  stages: StageTarget[];
  tenants: TenantTarget[];
  cultures: string[];
};

export type AvailableFirmwareReleaseTargets = {
  stages: StageTarget[];
  countries: string[];
};

export type StageTarget = {
  name: string;
  isAvailable: boolean;
};

export type TenantTarget = {
  stage: string;
  tenant: string;
  name: string;
  countryCode: string;
  languageCode: string;
};

export const TargetApi = {
  async GetAvailableFirmwareTargets(options?: AxiosRequestConfig): Promise<AvailableFirmwareReleaseTargets> {
    const result: AxiosResponse<AvailableFirmwareReleaseTargets> = await axios.get(
      'api/target/firmware-release',
      options,
    );

    return result.data;
  },

  async GetAvailableNotificationTargets(options?: AxiosRequestConfig): Promise<AvailableNotificationTargets> {
    const result: AxiosResponse<AvailableNotificationTargets> = await axios.get('api/target/notification', options);

    return result.data;
  },
};
