import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from 'assets/icons/logo.png';
import { Links } from 'navigation/routes';

import styles from './Header.module.scss';

export const Header: React.FC = () => (
  <header className={styles.header}>
    <div className={styles.topRow}>
      <div className={styles.topRowContent}>
        <img src={logo} alt={'prisma CLOUD'} />
      </div>
    </div>
    <div className={styles.bottomRow}>
      <div className={styles.bottomRowContent}>
        <HeaderLink to={Links.Authorized.Notifications.link()} title={'NOTIFICATIONS'} />
        <HeaderLink to={Links.Authorized.Firmware.link()} title={'FIRMWARE'} />
      </div>
    </div>
  </header>
);

const HeaderLink: React.FC<{ title: string; to: string; testId?: string }> = (props) => {
  const { title, to, testId } = props;

  return (
    <NavLink
      className={({ isActive }) => clsx(styles.headerItem, isActive && styles.headerItemActive)}
      to={to}
      data-testid={testId}
    >
      {title}
    </NavLink>
  );
};
