import { useIsFetching } from '@tanstack/react-query';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { createContext, useContext, useState } from 'react';
import {
  ArrayParam,
  NumberParam,
  createEnumArrayParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from 'react-router-url-params';

import { SortingType } from 'api/common';
import {
  FirmwareGenerations,
  FirmwareQueryParameters,
  FirmwareRecordDto,
  FirmwareSortingAttribute,
  FirmwareSortingAttributes,
} from 'api/firmware-api';
import { AppTable } from 'components/AppTable/AppTable';
import { Loading } from 'components/Loading/Loading';
import { useSortingControl } from 'components/SortableHeader/SortableHeader';
import { TopPanel } from 'features/FirmwarePage/Files/TopPanel/TopPanel';
import { useInfiniteScrolling } from 'helpers/useInfiniteScrolling';
import { getDownloadToketQueryKey } from 'helpers/react-query/query-keys';
import { useInfiniteFirmwareQuery } from 'helpers/useInfiniteFirmwareQuery';
import { CreateReleasesModal } from '../Forms/CreateReleases/CreateReleasesModal';
import { distributionTypes } from '../Forms/CreateReleases/utils';
import { DeleteFirmwareModal } from '../Forms/DeleteFirmwareModal';
import { useFirmwarePageColumns } from './columns';

import styles from './DeviceFirmwareListsPage.module.scss';

const defaultPageParams: FirmwareQueryParameters = {
  pageSize: 16,
  sortingType: SortingType.Descending,
  stages: [],
  generations: [],
  sortingAttribute: 'uploadedAt',
};

export type FirmwareFileSelectionType = 'deleting' | 'settingRelease';
export type SelectedFirmwareFile = {
  file: FirmwareRecordDto;
  selectionType: FirmwareFileSelectionType;
};

type SelectedFirmwareFileContext = {
  selectedFile: SelectedFirmwareFile | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<SelectedFirmwareFile | null>>;
};

export const SelectedFirmwareFileContext = createContext<SelectedFirmwareFileContext>({
  selectedFile: null,
  setSelectedFile: () => undefined,
});

export const DeviceFirmwareListsPage: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<SelectedFirmwareFile | null>(null);
  const [
    {
      selectedGenerations,
      selectedStages: stagesParam,
      selectedCountries: countriesParam,
      selectedDistributionTypes,
      selectedSortingType,
      selectedSortingAttribute,
    },
    setQueryParams,
  ] = useQueryParams({
    selectedGenerations: withDefault(createEnumArrayParam(FirmwareGenerations), []),
    selectedStages: withDefault(ArrayParam, []),
    selectedCountries: withDefault(ArrayParam, []),
    selectedDistributionTypes: withDefault(createEnumArrayParam(distributionTypes), []),
    selectedSortingType: withDefault(NumberParam, defaultPageParams.sortingType),
    selectedSortingAttribute: withDefault(
      createEnumParam([...FirmwareSortingAttributes]),
      defaultPageParams.sortingAttribute,
    ),
  });
  const isDownloadFileFetching = useIsFetching({
    queryKey: getDownloadToketQueryKey(),
  });

  const selectedStages = stagesParam.filter((x) => x) as string[];
  const selectedCountries = countriesParam.filter((x) => x) as string[];

  const query = useInfiniteFirmwareQuery({
    ...defaultPageParams,
    generations: selectedGenerations,
    countries: selectedCountries,
    stages: selectedStages,
    distributionTypes: selectedDistributionTypes,
    sortingType: selectedSortingType,
    sortingAttribute: selectedSortingAttribute,
  });
  const { isFetching, error } = query;

  const { ref, flatData } = useInfiniteScrolling(query, ({ items: sharedFiles }) => sharedFiles);

  const getSortingControl = (sortingAttribute: FirmwareSortingAttribute) =>
    useSortingControl(
      selectedSortingType,
      (sortingType: SortingType) =>
        setQueryParams({ selectedSortingType: sortingType, selectedSortingAttribute: sortingAttribute }),
      sortingAttribute === selectedSortingAttribute,
    );
  const columns = useFirmwarePageColumns({
    uploadedAtSortingControl: getSortingControl('uploadedAt'),
    versionSortingControl: getSortingControl('version'),
  });
  const table = useReactTable<FirmwareRecordDto>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    data: flatData,
    columns: columns,
  });

  return (
    <SelectedFirmwareFileContext.Provider
      value={{
        selectedFile: selectedFile,
        setSelectedFile: setSelectedFile,
      }}
    >
      <Loading loading={isFetching || !!isDownloadFileFetching} />
      <TopPanel
        selectedCountries={selectedCountries}
        onSelectedCountriesChange={(countries) => setQueryParams({ selectedCountries: countries })}
        selectedDistributionTypes={selectedDistributionTypes}
        onSelectedDistributionTypesChange={(distributionTypes) =>
          setQueryParams({ selectedDistributionTypes: distributionTypes })
        }
        selectedStages={selectedStages}
        onSelectedStagesChange={(stages) => setQueryParams({ selectedStages: stages })}
        selectedGenerations={selectedGenerations}
        onSelectedGenerationsChange={(generations) => setQueryParams({ selectedGenerations: generations })}
      />
      <AppTable
        table={table}
        estimateRowSize={() => 60}
        classNames={{ bodyCell: styles.cell, bodyRow: styles.row }}
        bottomRef={ref}
        error={error?.processedMessage}
      />
      <FirmwarePageForms />
    </SelectedFirmwareFileContext.Provider>
  );
};

const FirmwarePageForms = () =>
  useContext(SelectedFirmwareFileContext)?.selectedFile ? (
    <>
      <CreateReleasesModal />
      <DeleteFirmwareModal />
    </>
  ) : null;
