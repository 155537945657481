import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import { DistributionType } from 'api/firmware-api';
import { Field as FormField } from 'components/Field/Field';
import { FlagRadioGroup } from 'components/FlagRadioGroup/FlagRadioGroup';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { useAvailableFirmwareTargetsQuery } from 'helpers/react-query/firmware-query-hooks';
import { UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { DistributionWay, FirmwareReleaseModel, FirmwareReleases } from '../../utils';

import releaseStyles from './../FirmwareRelease.module.scss';

export type PrismaCloudStagesFieldProps = {
  releaseIndex: number;
  release: FirmwareReleaseModel;
  form: UseApplicationFormReturn<FirmwareReleases, any>;
};

export const PrismaCloudStagesField: React.FC<PrismaCloudStagesFieldProps> = ({ releaseIndex, release, form }) => {
  const index = release.distributionWays.findIndex((x) => x.type === DistributionType.PrismaCloud);
  const distribution = index !== -1 && release.distributionWays.at(index);

  return distribution && <Field distribution={distribution} index={index} form={form} releaseIndex={releaseIndex} />;
};

type FieldProps = {
  form: UseApplicationFormReturn<FirmwareReleases, any>;
  releaseIndex: number;
  distribution: DistributionWay;
  index: number;
};

const Field: React.FC<FieldProps> = ({ distribution, form: { register, control, formState }, index, releaseIndex }) => {
  const errors = formState.errors.releases?.[releaseIndex]?.distributionWays?.[index]?.stages;
  const stages = useAvailableFirmwareTargetsQuery().data?.stages;
  const path: FieldPath<FirmwareReleases> = `releases.${releaseIndex}.distributionWays.${index}`;

  const name = register(`${path}.stages`, {
    required: { value: !distribution.allStages, message: 'Required' },
  }).name;

  return (
    <FormField title={'pCLOUD stages'} fieldClassName={releaseStyles.complexField}>
      <FlagRadioGroup
        control={control}
        name={`${path}.allStages`}
        labelStrategy={(val: boolean) => (val ? 'All' : 'Specific')}
      />
      {!distribution.allStages && (
        <Controller
          control={control}
          name={name}
          render={({ field: { ref, ...field } }) => (
            <MultiSelect
              {...field}
              placeholder={'Select stages'}
              options={stages ? stages.map((x) => x.name) : []}
              error={!!errors}
              helperText={errors?.message}
            />
          )}
        />
      )}
    </FormField>
  );
};
