import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { NotificationsApi } from 'api/notifications-api';
import { TargetApi } from 'api/target-api';
import {
  GetAvailableNotificationTargetsQueryKey,
  getFirmwarePageQueryKey,
  getNotificationsPageQueryKey,
} from './query-keys';

export const useInvalidateNotificationsPageQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(() => queryClient.invalidateQueries({ queryKey: getNotificationsPageQueryKey() }), [queryClient]);
};

export const useInvalidateFirmwarePageQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(() => queryClient.invalidateQueries({ queryKey: getFirmwarePageQueryKey() }), [queryClient]);
};

export const useNotificationsDisableMutation = (onSuccess?: () => void) =>
  useMutation({
    mutationFn: (id: string) => NotificationsApi.disableNotification(id),
    onSuccess,
  });

export const useNotificationsDeleteMutation = (onSuccess?: () => void) =>
  useMutation({
    mutationFn: (id: string) => NotificationsApi.deleteNotification(id),
    onSuccess,
  });

export const useNotificationsPublishMutation = (onSuccess?: () => void) =>
  useMutation({
    mutationFn: (id: string) => NotificationsApi.publishNotification(id),
    onSuccess,
  });

export const useNotificationsUnpublishMutation = (onSuccess?: () => void) =>
  useMutation({
    mutationFn: (id: string) => NotificationsApi.unpublishNotification(id),
    onSuccess,
  });

export const useAvailableNotificationTargetsQuery = () =>
  useQuery({
    queryKey: GetAvailableNotificationTargetsQueryKey(),
    queryFn: () => TargetApi.GetAvailableNotificationTargets(),
    staleTime: 1000 * 60 * 15, // 15 minutes.
  });
