import { DefaultOptions, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import React, { useMemo } from 'react';

import { MsgUnexpectedError } from 'helpers/constants';

export const ConnectQueryClient: React.FC<{ queryClientOptions?: DefaultOptions; children?: React.ReactNode }> = (
  props,
) => {
  const { queryClientOptions } = props;

  const retryFn = (failureNumber: number, e: unknown) => {
    let status = isAxiosError(e) && e?.response?.status;
    return failureNumber < 3 && !!status && ![404, 400].includes(status);
  };

  const queryClient = useMemo(() => {
    return new QueryClient({
      queryCache: new QueryCache({
        onError: (error) => {
          error.processedMessage = error.message;

          if (isAxiosError(error)) {
            const status = error.response?.status;
            if (!status || status >= 500) error.processedMessage = MsgUnexpectedError;
          }
        },
      }),
      defaultOptions: {
        queries: {
          retry: retryFn,
          refetchOnWindowFocus: false,
          ...queryClientOptions?.queries,
        },
        mutations: {
          retry: retryFn,
          ...queryClientOptions?.mutations,
        },
      },
    });
  }, [queryClientOptions]);

  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};
