import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AlertProvider } from 'components/CustomAlert';
import { DeviceFirmwareListsPage } from 'features/FirmwarePage/Files/DeviceFirmwareListsPage/DeviceFirmwareListsPage';
import { FirmwarePage } from 'features/FirmwarePage/FirmwarePage';
import { DeviceWhiteListsPage } from 'features/FirmwarePage/WhiteLists/DeviceWhiteListsPage/DeviceWhiteListsPage';
import { Header } from 'features/Header/Header';
import { NotificationsPage } from 'features/NotificationsPage/NotificationsPage/NotificationsPage';
import { useIsAuthorized } from 'helpers/auth/auth-interceptor';
import { getDateLocale } from 'helpers/dateUtils';
import { Links } from './navigation/routes';
import { redirectToLoginPage } from './openid/openid-manager';
import { theme } from './theme';

import styles from './App.module.scss';

export function App() {
  const isAuth = useIsAuthorized();

  useEffect(() => {
    if (!isAuth) {
      redirectToLoginPage();
    }
  }, []);

  return (
    <AlertProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateLocale()}>
          <div className={styles.container}>
            <Header />
            <div className={styles.content}>
              <Routes>
                <Route path={'/*'} element={<Navigate to={Links.Authorized.Notifications.route} />} />
                <Route path={Links.Authorized.Notifications.route} element={<NotificationsPage />} />
                <Route
                  path={Links.Authorized.Firmware.route}
                  element={<Navigate to={Links.Authorized.FirmwareFiles.route} />}
                />
                <Route path={Links.Authorized.Firmware.route} element={<FirmwarePage />}>
                  <Route path={Links.Authorized.FirmwareFiles.route} element={<DeviceFirmwareListsPage />} />
                  <Route path={Links.Authorized.WhiteLists.route} element={<DeviceWhiteListsPage />} />
                </Route>
              </Routes>
            </div>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </AlertProvider>
  );
}
