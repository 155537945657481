import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { GetAvailableFirmwareTargetsQueryKey, getFirmwarePageQueryKey } from './query-keys';
import { TargetApi } from 'api/target-api';

export const useInvalidateFirmwarePageQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(() => queryClient.invalidateQueries({ queryKey: getFirmwarePageQueryKey() }), [queryClient]);
};

export const useAvailableFirmwareTargetsQuery = () =>
  useQuery({
    queryKey: GetAvailableFirmwareTargetsQueryKey(),
    queryFn: () => TargetApi.GetAvailableFirmwareTargets(),
    staleTime: 1000 * 60 * 15, // 15 minutes.
  });
