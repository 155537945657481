import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { PageDto } from 'api/common';

export function useInfiniteScrolling<TItem, TError, TModel>(
  query: UseInfiniteQueryResult<InfiniteData<PageDto<TItem>>, TError>,
  pageParser: (a: PageDto<TItem>) => TModel[],
) {
  const { fetchNextPage, isError, data, isFetching, hasNextPage } = query;
  const { ref, inView } = useInView();
  const flatData = useFlattenedPageData(data, pageParser);

  // Virtualized table may not render new last row in the table after loading a new page
  // and for some reason inView is TRUE in this situation, so in order to determine
  // whether last element is actually rendered we introduce our own ref
  const trackingRef = useRef<Element | null>(null);

  useEffect(() => {
    if (isError) return;
    if (trackingRef.current && inView && !isFetching && hasNextPage) fetchNextPage();
  }, [inView]);

  return {
    ref: (el: Element | null) => {
      trackingRef.current = el;
      ref(el);
    },
    flatData,
  };
}

const useFlattenedPageData = <TData, TModel>(
  rawData: InfiniteData<TData> | undefined,
  pageParser: (a: TData) => TModel[],
): TModel[] => useMemo(() => rawData?.pages.map(pageParser).flatMap((page) => page) ?? [], [rawData]);
