// import {
//   QueryFunction,
//   QueryKey,
//   UseInfiniteQueryOptions,
//   UseInfiniteQueryResult,
//   useInfiniteQuery as useRawInfiniteQuery,
// } from '@tanstack/react-query';

import { useInfiniteQuery } from '@tanstack/react-query';
import { NotificationsApi, NotificationsQueryParameters } from 'api/notifications-api';
import { getNextPageParam } from './react-query/infinite-queries-utils';
import { getNotificationsPageQueryKey } from './react-query/query-keys';

export function useInfiniteNotificationsQuery(queryParams: Omit<NotificationsQueryParameters, 'pageNumber'>) {
  return useInfiniteQuery({
    queryKey: getNotificationsPageQueryKey(queryParams),
    queryFn: async ({ pageParam }) =>
      await NotificationsApi.getNotifications({
        ...queryParams,
        pageNumber: pageParam,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => getNextPageParam(lastPage, pages, queryParams),
  });
}
