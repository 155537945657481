import { Column, Table as TanTable, flexRender } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';

import styles from './AppTable.module.scss';

export type AppTableProps<TData> = {
  table: TanTable<TData>;
  bottomRef?: (node: Element | null) => void;
  estimateRowSize: (rowIndex: number) => number;
  classNames?: AppTableClassNames;
  error?: ReactNode;
};

export type AppTableClassNames = {
  container?: string;
  table?: string;
  head?: string;
  headRow?: string;
  headCell?: string;
  body?: string;
  bodyRow?: string;
  bodyCell?: string;
};

export function AppTable<TData>(props: AppTableProps<TData>) {
  const { table, bottomRef, estimateRowSize, classNames, error } = props;
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: estimateRowSize,
    getScrollElement: () => tableContainerRef.current,
    //https://github.com/TanStack/table/blob/2ec0d292583510d912758e4360678fcc03108ed0/examples/react/virtualized-rows/src/main.tsx#L87
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const columns = useMemo(() => table.getAllFlatColumns(), [table]);
  const cumulativeSizeRef = useRef<number>(0);
  useEffect(() => {
    cumulativeSizeRef.current = columns.reduce((size, x) => size + x.getSize(), 0);
  }, [columns]);

  const { width: tableContainerWidth } = useResizeObserver<HTMLDivElement>({ ref: tableContainerRef });

  useEffect(() => {
    columns.forEach((x) => {
      x.columnDef.meta = x.columnDef.meta ?? {};
      const width = tableContainerWidth ?? cumulativeSizeRef.current;
      const columnWeight = x.columnDef.size ?? width / columns.length;
      x.columnDef.meta._computedSize = (columnWeight * width) / cumulativeSizeRef.current;
    });
  }, [tableContainerWidth, cumulativeSizeRef.current]);

  const getColumnMaxWidth = useCallback(
    (column: Column<TData, unknown>) =>
      column.columnDef.meta?.maxSize
        ? `${column.columnDef.meta?.maxSize}px`
        : `${column.columnDef.meta?._computedSize ?? column.getSize()}fr`,
    [],
  );
  const getColumnMinWidth = useCallback(
    (column: Column<TData, unknown>) => column.columnDef.meta?.minSize ?? column.getSize(),
    [],
  );

  const getGridTemplateColumns = () =>
    table
      .getAllFlatColumns()
      .map((x) => `minmax(${getColumnMinWidth(x)}px, ${getColumnMaxWidth(x)})`)
      .join(' ');

  return (
    <div ref={tableContainerRef} className={clsx(styles.tableContainer, classNames?.container)}>
      <table style={{ display: 'grid' }} className={classNames?.table}>
        <thead className={clsx(styles.tableHead, classNames?.head)}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              style={{
                gridTemplateColumns: getGridTemplateColumns(),
              }}
              key={headerGroup.id}
              className={clsx(styles.tableHeadRow, classNames?.headRow)}
            >
              {headerGroup.headers.map((header) => {
                const props = header.column.columnDef.meta?.headCellProps;
                return (
                  <th
                    key={header.id}
                    {...props}
                    className={clsx(styles.tableHeadCell, classNames?.headCell, props?.className)}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          className={clsx(styles.tableBody, classNames?.body)}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index];
            return (
              <tr
                data-index={virtualRow.index}
                ref={(el) => rowVirtualizer.measureElement(el)}
                key={row.id}
                data-testid={`table__row-${row.id}`}
                className={clsx(styles.tableBodyRow, classNames?.bodyRow)}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                  gridTemplateColumns: getGridTemplateColumns(),
                }}
                {...(virtualRow.index + 1 === rows.length &&
                  bottomRef && {
                    ref: (el: Element | null) => {
                      bottomRef(el);
                      rowVirtualizer.measureElement(el);
                    },
                  })}
              >
                {row.getVisibleCells().map((cell) => {
                  const props = cell.column.columnDef.meta?.bodyCellProps;
                  return (
                    <td
                      key={cell.id}
                      {...props}
                      className={clsx(styles.tableBodyCell, classNames?.bodyCell, props?.className)}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {(rows.length === 0 || error) && (
        <div className={styles.tableMessageContainer}>
          <div className={clsx(styles.tableMessage, error && styles.errorMessage)}>{error ?? 'Nothing to show'}</div>
        </div>
      )}
    </div>
  );
}
